var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container-myProfile-mobile pt-0",
      class: `${_vm.$screen.width <= 576 ? "my-project" : ""}`,
    },
    [
      _vm.isFilmSingle
        ? _c("div", { staticClass: "container-mobile animated fadeIn" }, [
            _c(
              "div",
              { staticClass: "content-myTimesheet-mobile" },
              [
                _c(
                  "p",
                  {
                    staticClass: "fw-400 my-1",
                    staticStyle: {
                      "font-size": "13px !important",
                      color: "rgba(6, 38, 62, 0.65)",
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t* " +
                        _vm._s(
                          _vm.FormMSG(
                            1,
                            "Click to a timesheet to see the details.Timesheets are divided by week from Monday to Sunday."
                          )
                        ) +
                        "\n\t\t\t"
                    ),
                  ]
                ),
                _c(
                  "b-row",
                  [
                    _c("b-col", [
                      _vm.timesheetsByDate.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "accordion",
                              attrs: { role: "tablist" },
                            },
                            _vm._l(
                              _vm.timesheetsByDate,
                              function (timesheet, j) {
                                return _c(
                                  "div",
                                  {
                                    key: j,
                                    staticStyle: { "margin-top": "16px" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "b-toggle",
                                            rawName: "v-b-toggle",
                                            value: timesheet.accordionName,
                                            expression:
                                              "timesheet.accordionName",
                                          },
                                        ],
                                        staticClass: "button-month-label",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleAccordionClick(
                                              timesheet,
                                              j
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticStyle: { width: "90%" } },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(timesheet.month) +
                                                  "\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-end align-items-center",
                                            staticStyle: { width: "10%" },
                                          },
                                          [
                                            _c(
                                              _vm.getLucideIcon("ChevronDown"),
                                              {
                                                tag: "component",
                                                attrs: {
                                                  color: "#225CBD",
                                                  size: 16,
                                                  "stroke-width": 2.5,
                                                },
                                              }
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: timesheet.accordionName,
                                          accordion:
                                            "accordion-my-timesheet-month",
                                          visible: timesheet.visible,
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        timesheet.listWeeks.length === 0
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "text-center",
                                                staticStyle: {
                                                  "margin-top": "16px",
                                                  "font-size": "0.9rem",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        23,
                                                        "No timesheet found"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._l(
                                          timesheet.listWeeks,
                                          function (byWeek, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticStyle: { padding: "8px" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      cursor: "pointer",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleClickRow(
                                                          byWeek,
                                                          timesheet.firstDateOfMonth
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-row justify-content-center align-items-start",
                                                        staticStyle: {
                                                          "background-color":
                                                            "#f5f7f9",
                                                          padding: "16px",
                                                          "border-radius":
                                                            "8px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-col",
                                                          {
                                                            staticClass: "p-0",
                                                          },
                                                          [
                                                            _c(
                                                              "b-row",
                                                              [
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "text-left",
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "0.85rem !important",
                                                                            color:
                                                                              "#06263e",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                            _vm._s(
                                                                              byWeek.strDateString
                                                                            ) +
                                                                            " - " +
                                                                            _vm._s(
                                                                              byWeek.endDateString
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "6",
                                                                    },
                                                                  },
                                                                  [
                                                                    !_vm.isFilmSingle
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                "margin-bottom":
                                                                                  "8px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "wrap-status d-flex justify-content-end",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    class: `status ${byWeek.statusClass}`,
                                                                                    staticStyle:
                                                                                      {
                                                                                        "font-size":
                                                                                          "0.675rem",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                        _vm._s(
                                                                                          byWeek.validatedStatus
                                                                                        ) +
                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-row",
                                                              {
                                                                staticClass:
                                                                  "text-center",
                                                                staticStyle: {
                                                                  "margin-top":
                                                                    "12px",
                                                                },
                                                              },
                                                              [
                                                                _vm.canSeeSalary
                                                                  ? _c(
                                                                      "b-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "4",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "h2",
                                                                          [
                                                                            _vm._v(
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                _vm._s(
                                                                                  _vm.getCurrency(
                                                                                    byWeek.salary
                                                                                  )
                                                                                ) +
                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex flex-row align-items-center justify-content-center w-100",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "salary-log",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    item: byWeek,
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "p",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      3,
                                                                                      "Salary"
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    staticClass:
                                                                      "text-center",
                                                                    attrs: {
                                                                      cols: "4",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("h2", [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            _vm.getHhMmString(
                                                                              byWeek.hhmm
                                                                            )
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ]),
                                                                    _c(
                                                                      "p",
                                                                      [
                                                                        _c(
                                                                          "Clock10",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "#06263ED6",
                                                                                size: 16,
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                4,
                                                                                "Worked"
                                                                              )
                                                                            ) +
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    staticClass:
                                                                      "text-center pr-0 pl-0",
                                                                    attrs: {
                                                                      cols: "4",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("h2", [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            _vm.getCO2(
                                                                              byWeek.kgCoTwo
                                                                            )
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ]),
                                                                    _c(
                                                                      "p",
                                                                      [
                                                                        _c(
                                                                          "Sprout",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "#00A09C",
                                                                                size: 16,
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tCO"
                                                                        ),
                                                                        _c(
                                                                          "sub",
                                                                          [
                                                                            _vm._v(
                                                                              "2"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _c(
            "b-tabs",
            {
              attrs: {
                "active-nav-item-class": "font-weight-bold",
                "active-tab-class": "font-weight-bold",
              },
              model: {
                value: _vm.activeTabIndex,
                callback: function ($$v) {
                  _vm.activeTabIndex = $$v
                },
                expression: "activeTabIndex",
              },
            },
            [
              _vm.canSeeApprove
                ? _c(
                    "b-tab",
                    { attrs: { title: _vm.FormMSG(13031, "Approve") } },
                    [_c("ValidateTimesheets")],
                    1
                  )
                : _vm._e(),
              _c("b-tab", { attrs: { title: _vm.FormMSG(13034, "Drafts") } }, [
                _c(
                  "div",
                  { staticClass: "container-mobile animated pt-0 fadeIn" },
                  [
                    _c(
                      "div",
                      { staticClass: "content-myTimesheet-mobile pt-0" },
                      [
                        _c(
                          "p",
                          {
                            staticClass: "fw-400 my-1",
                            staticStyle: {
                              "font-size": "13px !important",
                              color: "rgba(6, 38, 62, 0.65)",
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t* " +
                                _vm._s(
                                  _vm.FormMSG(
                                    1,
                                    "Click to a timesheet to see the details.Timesheets are divided by week from Monday to Sunday."
                                  )
                                ) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _c(
                          "b-row",
                          [
                            _c("b-col", [
                              _vm.timesheetsByDate.length > 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "accordion",
                                      attrs: { role: "tablist" },
                                    },
                                    _vm._l(
                                      _vm.timesheetsByDate,
                                      function (timesheet, j) {
                                        return _c(
                                          "div",
                                          {
                                            key: j,
                                            staticStyle: {
                                              "margin-top": "16px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-toggle",
                                                    rawName: "v-b-toggle",
                                                    value:
                                                      timesheet.accordionName,
                                                    expression:
                                                      "timesheet.accordionName",
                                                  },
                                                ],
                                                staticClass:
                                                  "button-month-label",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleAccordionClick(
                                                      timesheet,
                                                      j
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "90%",
                                                    },
                                                  },
                                                  [
                                                    _c("p", [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            timesheet.month
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-end align-items-center",
                                                    staticStyle: {
                                                      width: "10%",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ChevronDown"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: {
                                                          color: "#225CBD",
                                                          size: 16,
                                                          "stroke-width": 2.5,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-collapse",
                                              {
                                                attrs: {
                                                  id: timesheet.accordionName,
                                                  accordion:
                                                    "accordion-my-timesheet-month",
                                                  visible: timesheet.visible,
                                                  role: "tabpanel",
                                                },
                                              },
                                              [
                                                timesheet.listWeeks.length === 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-center",
                                                        staticStyle: {
                                                          "margin-top": "16px",
                                                          "font-size": "0.9rem",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                23,
                                                                "No timesheet found"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._l(
                                                  timesheet.listWeeks,
                                                  function (byWeek, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticStyle: {
                                                          padding: "8px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              cursor: "pointer",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleClickRow(
                                                                  byWeek,
                                                                  timesheet.firstDateOfMonth
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex flex-row justify-content-center align-items-start",
                                                                staticStyle: {
                                                                  "background-color":
                                                                    "#f5f7f9",
                                                                  padding:
                                                                    "16px",
                                                                  "border-radius":
                                                                    "8px",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    staticClass:
                                                                      "p-0",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-row",
                                                                      [
                                                                        _c(
                                                                          "b-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "6",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "text-left",
                                                                                staticStyle:
                                                                                  {
                                                                                    "font-size":
                                                                                      "0.85rem !important",
                                                                                    color:
                                                                                      "#06263e",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                    _vm._s(
                                                                                      byWeek.strDateString
                                                                                    ) +
                                                                                    " - " +
                                                                                    _vm._s(
                                                                                      byWeek.endDateString
                                                                                    ) +
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "b-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "6",
                                                                              },
                                                                          },
                                                                          [
                                                                            !_vm.isFilmSingle
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        "margin-bottom":
                                                                                          "8px",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "wrap-status d-flex justify-content-end",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            class: `status ${byWeek.statusClass}`,
                                                                                            staticStyle:
                                                                                              {
                                                                                                "font-size":
                                                                                                  "0.675rem",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                _vm._s(
                                                                                                  byWeek.validatedStatus
                                                                                                ) +
                                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "b-row",
                                                                      {
                                                                        staticClass:
                                                                          "text-center",
                                                                        staticStyle:
                                                                          {
                                                                            "margin-top":
                                                                              "12px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm.canSeeSalary
                                                                          ? _c(
                                                                              "b-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "4",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "h2",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                        _vm._s(
                                                                                          _vm.getCurrency(
                                                                                            byWeek.salary
                                                                                          )
                                                                                        ) +
                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "d-flex flex-row align-items-center justify-content-center w-100",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "salary-log",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            item: byWeek,
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.FormMSG(
                                                                                              3,
                                                                                              "Salary"
                                                                                            )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _c(
                                                                          "b-col",
                                                                          {
                                                                            staticClass:
                                                                              "text-center",
                                                                            attrs:
                                                                              {
                                                                                cols: "4",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "h2",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                    _vm._s(
                                                                                      _vm.getHhMmString(
                                                                                        byWeek.hhmm
                                                                                      )
                                                                                    ) +
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "p",
                                                                              [
                                                                                _c(
                                                                                  "Clock10",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        color:
                                                                                          "#06263ED6",
                                                                                        size: 16,
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                    _vm._s(
                                                                                      _vm.FormMSG(
                                                                                        4,
                                                                                        "Worked"
                                                                                      )
                                                                                    ) +
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "b-col",
                                                                          {
                                                                            staticClass:
                                                                              "text-center pr-0 pl-0",
                                                                            attrs:
                                                                              {
                                                                                cols: "4",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "h2",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                    _vm._s(
                                                                                      _vm.getCO2(
                                                                                        byWeek.kgCoTwo
                                                                                      )
                                                                                    ) +
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "p",
                                                                              [
                                                                                _c(
                                                                                  "Sprout",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        color:
                                                                                          "#00A09C",
                                                                                        size: 16,
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tCO"
                                                                                ),
                                                                                _c(
                                                                                  "sub",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "2"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _c("b-tab", { attrs: { title: _vm.FormMSG(13032, "Pending") } }, [
                _c(
                  "div",
                  { staticClass: "container-mobile animated pt-0 fadeIn" },
                  [
                    _c(
                      "div",
                      { staticClass: "content-myTimesheet-mobile pt-0" },
                      [
                        _c(
                          "p",
                          {
                            staticClass: "fw-400 my-1",
                            staticStyle: {
                              "font-size": "13px !important",
                              color: "rgba(6, 38, 62, 0.65)",
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t* " +
                                _vm._s(
                                  _vm.FormMSG(
                                    1,
                                    "Click to a timesheet to see the details.Timesheets are divided by week from Monday to Sunday."
                                  )
                                ) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _c(
                          "b-row",
                          [
                            _c("b-col", [
                              _vm.timesheetsByDate.length > 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "accordion",
                                      attrs: { role: "tablist" },
                                    },
                                    _vm._l(
                                      _vm.timesheetsByDate,
                                      function (timesheet, j) {
                                        return _c(
                                          "div",
                                          {
                                            key: j,
                                            staticStyle: {
                                              "margin-top": "16px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-toggle",
                                                    rawName: "v-b-toggle",
                                                    value:
                                                      timesheet.accordionName,
                                                    expression:
                                                      "timesheet.accordionName",
                                                  },
                                                ],
                                                staticClass:
                                                  "button-month-label",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleAccordionClick(
                                                      timesheet,
                                                      j
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "90%",
                                                    },
                                                  },
                                                  [
                                                    _c("p", [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            timesheet.month
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-end align-items-center",
                                                    staticStyle: {
                                                      width: "10%",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ChevronDown"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: {
                                                          color: "#225CBD",
                                                          size: 16,
                                                          "stroke-width": 2.5,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-collapse",
                                              {
                                                attrs: {
                                                  id: timesheet.accordionName,
                                                  accordion:
                                                    "accordion-my-timesheet-month",
                                                  visible: timesheet.visible,
                                                  role: "tabpanel",
                                                },
                                              },
                                              [
                                                timesheet.listWeeks.length === 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-center",
                                                        staticStyle: {
                                                          "margin-top": "16px",
                                                          "font-size": "0.9rem",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                23,
                                                                "No timesheet found"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._l(
                                                  timesheet.listWeeks,
                                                  function (byWeek, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticStyle: {
                                                          padding: "8px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              cursor: "pointer",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleClickRow(
                                                                  byWeek,
                                                                  timesheet.firstDateOfMonth
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex flex-row justify-content-center align-items-start",
                                                                staticStyle: {
                                                                  "background-color":
                                                                    "#f5f7f9",
                                                                  padding:
                                                                    "16px",
                                                                  "border-radius":
                                                                    "8px",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    staticClass:
                                                                      "p-0",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-row",
                                                                      [
                                                                        _c(
                                                                          "b-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "6",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "text-left",
                                                                                staticStyle:
                                                                                  {
                                                                                    "font-size":
                                                                                      "0.85rem !important",
                                                                                    color:
                                                                                      "#06263e",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                    _vm._s(
                                                                                      byWeek.strDateString
                                                                                    ) +
                                                                                    " - " +
                                                                                    _vm._s(
                                                                                      byWeek.endDateString
                                                                                    ) +
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "b-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "6",
                                                                              },
                                                                          },
                                                                          [
                                                                            !_vm.isFilmSingle
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        "margin-bottom":
                                                                                          "8px",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "wrap-status d-flex justify-content-end",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            class: `status ${byWeek.statusClass}`,
                                                                                            staticStyle:
                                                                                              {
                                                                                                "font-size":
                                                                                                  "0.675rem",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                _vm._s(
                                                                                                  byWeek.validatedStatus
                                                                                                ) +
                                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "b-row",
                                                                      {
                                                                        staticClass:
                                                                          "text-center",
                                                                        staticStyle:
                                                                          {
                                                                            "margin-top":
                                                                              "12px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm.canSeeSalary
                                                                          ? _c(
                                                                              "b-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "4",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "h2",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                        _vm._s(
                                                                                          _vm.getCurrency(
                                                                                            byWeek.salary
                                                                                          )
                                                                                        ) +
                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "d-flex flex-row align-items-center justify-content-center w-100",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "salary-log",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            item: byWeek,
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.FormMSG(
                                                                                              3,
                                                                                              "Salary"
                                                                                            )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _c(
                                                                          "b-col",
                                                                          {
                                                                            staticClass:
                                                                              "text-center",
                                                                            attrs:
                                                                              {
                                                                                cols: "4",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "h2",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                    _vm._s(
                                                                                      _vm.getHhMmString(
                                                                                        byWeek.hhmm
                                                                                      )
                                                                                    ) +
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "p",
                                                                              [
                                                                                _c(
                                                                                  "Clock10",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        color:
                                                                                          "#06263ED6",
                                                                                        size: 16,
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                    _vm._s(
                                                                                      _vm.FormMSG(
                                                                                        4,
                                                                                        "Worked"
                                                                                      )
                                                                                    ) +
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "b-col",
                                                                          {
                                                                            staticClass:
                                                                              "text-center pr-0 pl-0",
                                                                            attrs:
                                                                              {
                                                                                cols: "4",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "h2",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                    _vm._s(
                                                                                      _vm.getCO2(
                                                                                        byWeek.kgCoTwo
                                                                                      )
                                                                                    ) +
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "p",
                                                                              [
                                                                                _c(
                                                                                  "Sprout",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        color:
                                                                                          "#00A09C",
                                                                                        size: 16,
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tCO"
                                                                                ),
                                                                                _c(
                                                                                  "sub",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "2"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _c(
                "b-tab",
                {
                  attrs: {
                    "title-link-class": "linkClass",
                    title: _vm.FormMSG(13033, "Approved"),
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "container-mobile pt-0 third animated fadeIn",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "content-myTimesheet-mobile pt-0" },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "fw-400 my-1",
                              staticStyle: {
                                "font-size": "13px !important",
                                color: "rgba(6, 38, 62, 0.65)",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t* " +
                                  _vm._s(
                                    _vm.FormMSG(
                                      1,
                                      "Click to a timesheet to see the details.Timesheets are divided by week from Monday to Sunday."
                                    )
                                  ) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _vm.timesheetsByDate.length > 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "accordion",
                                        attrs: { role: "tablist" },
                                      },
                                      _vm._l(
                                        _vm.timesheetsByDate,
                                        function (timesheet, j) {
                                          return _c(
                                            "div",
                                            {
                                              key: j,
                                              staticStyle: {
                                                "margin-top": "16px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName: "v-b-toggle",
                                                      value:
                                                        timesheet.accordionName,
                                                      expression:
                                                        "timesheet.accordionName",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "button-month-label",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleAccordionClick(
                                                        timesheet,
                                                        j
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "90%",
                                                      },
                                                    },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              timesheet.month
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-content-end align-items-center",
                                                      staticStyle: {
                                                        width: "10%",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "ChevronDown"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            color: "#225CBD",
                                                            size: 16,
                                                            "stroke-width": 2.5,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "b-collapse",
                                                {
                                                  attrs: {
                                                    id: timesheet.accordionName,
                                                    accordion:
                                                      "accordion-my-timesheet-month",
                                                    visible: timesheet.visible,
                                                    role: "tabpanel",
                                                  },
                                                },
                                                [
                                                  timesheet.listWeeks.length ===
                                                  0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-center",
                                                          staticStyle: {
                                                            "margin-top":
                                                              "16px",
                                                            "font-size":
                                                              "0.9rem",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  23,
                                                                  "No timesheet found"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._l(
                                                    timesheet.listWeeks,
                                                    function (byWeek, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: index,
                                                          staticStyle: {
                                                            padding: "8px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                cursor:
                                                                  "pointer",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleClickRow(
                                                                      byWeek,
                                                                      timesheet.firstDateOfMonth
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex flex-row justify-content-center align-items-start",
                                                                  staticStyle: {
                                                                    "background-color":
                                                                      "#f5f7f9",
                                                                    padding:
                                                                      "16px",
                                                                    "border-radius":
                                                                      "8px",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-col",
                                                                    {
                                                                      staticClass:
                                                                        "p-0",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-row",
                                                                        [
                                                                          _c(
                                                                            "b-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-left",
                                                                                  staticStyle:
                                                                                    {
                                                                                      "font-size":
                                                                                        "0.85rem !important",
                                                                                      color:
                                                                                        "#06263e",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                      _vm._s(
                                                                                        byWeek.strDateString
                                                                                      ) +
                                                                                      " - " +
                                                                                      _vm._s(
                                                                                        byWeek.endDateString
                                                                                      ) +
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "b-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              !_vm.isFilmSingle
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          "margin-bottom":
                                                                                            "8px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "wrap-status d-flex justify-content-end",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              class: `status ${byWeek.statusClass}`,
                                                                                              staticStyle:
                                                                                                {
                                                                                                  "font-size":
                                                                                                    "0.675rem",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                  _vm._s(
                                                                                                    byWeek.validatedStatus
                                                                                                  ) +
                                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "b-row",
                                                                        {
                                                                          staticClass:
                                                                            "text-center",
                                                                          staticStyle:
                                                                            {
                                                                              "margin-top":
                                                                                "12px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm.canSeeSalary
                                                                            ? _c(
                                                                                "b-col",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      cols: "4",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "h2",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                          _vm._s(
                                                                                            _vm.getCurrency(
                                                                                              byWeek.salary
                                                                                            )
                                                                                          ) +
                                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex flex-row align-items-center justify-content-center w-100",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "salary-log",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              item: byWeek,
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                      _c(
                                                                                        "p",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.FormMSG(
                                                                                                3,
                                                                                                "Salary"
                                                                                              )
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _c(
                                                                            "b-col",
                                                                            {
                                                                              staticClass:
                                                                                "text-center",
                                                                              attrs:
                                                                                {
                                                                                  cols: "4",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "h2",
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                      _vm._s(
                                                                                        _vm.getHhMmString(
                                                                                          byWeek.hhmm
                                                                                        )
                                                                                      ) +
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "Clock10",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            "#06263ED6",
                                                                                          size: 16,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                      _vm._s(
                                                                                        _vm.FormMSG(
                                                                                          4,
                                                                                          "Worked"
                                                                                        )
                                                                                      ) +
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "b-col",
                                                                            {
                                                                              staticClass:
                                                                                "text-center pr-0 pl-0",
                                                                              attrs:
                                                                                {
                                                                                  cols: "4",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "h2",
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                      _vm._s(
                                                                                        _vm.getCO2(
                                                                                          byWeek.kgCoTwo
                                                                                        )
                                                                                      ) +
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "p",
                                                                                [
                                                                                  _c(
                                                                                    "Sprout",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            "#00A09C",
                                                                                          size: 16,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tCO"
                                                                                  ),
                                                                                  _c(
                                                                                    "sub",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "2"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
      _vm.activeTabIndex === _vm.indexOfDraft &&
      _vm.timeSheetsDrafts.length > 0 &&
      _vm.canSubmit
        ? _c(
            "div",
            [
              _c(
                "b-row",
                { staticClass: "footer-fixed" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "font-weight": "700",
                          },
                          attrs: { variant: "primary", block: "" },
                          on: { click: _vm.sendForValidation },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.FormMSG(
                                1521,
                                "Send all not submitted to validation"
                              )
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }