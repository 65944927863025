<template>
	<div class="container-myProfile-mobile pt-0" :class="`${$screen.width <= 576 ? 'my-project' : ''}`">
		<div v-if="isFilmSingle" class="container-mobile animated fadeIn">
			<div class="content-myTimesheet-mobile">
				<p class="fw-400 my-1" style="font-size: 13px !important; color: rgba(6, 38, 62, 0.65)">
					* {{ FormMSG(1, 'Click to a timesheet to see the details.Timesheets are divided by week from Monday to Sunday.') }}
				</p>
				<b-row>
					<b-col>
						<div class="accordion" role="tablist" v-if="timesheetsByDate.length > 0">
							<div v-for="(timesheet, j) in timesheetsByDate" :key="j" style="margin-top: 16px">
								<div v-b-toggle="timesheet.accordionName" class="button-month-label" @click="handleAccordionClick(timesheet, j)">
									<div style="width: 90%">
										<p>
											{{ timesheet.month }}
										</p>
									</div>
									<div style="width: 10%" class="d-flex justify-content-end align-items-center">
										<component :is="getLucideIcon('ChevronDown')" color="#225CBD" :size="16" :stroke-width="2.5"> </component>
									</div>
								</div>
								<b-collapse :id="timesheet.accordionName" accordion="accordion-my-timesheet-month" :visible="timesheet.visible" role="tabpanel">
									<div v-if="timesheet.listWeeks.length === 0" class="text-center" style="margin-top: 16px; font-size: 0.9rem">
										{{ FormMSG(23, 'No timesheet found') }}
									</div>
									<div v-for="(byWeek, index) in timesheet.listWeeks" :key="index" style="padding: 8px">
										<div style="cursor: pointer" @click="handleClickRow(byWeek, timesheet.firstDateOfMonth)">
											<div
												style="background-color: #f5f7f9; padding: 16px; border-radius: 8px"
												class="d-flex flex-row justify-content-center align-items-start"
											>
												<b-col class="p-0">
													<b-row>
														<b-col cols="6">
															<span class="text-left" style="font-size: 0.85rem !important; color: #06263e">
																{{ byWeek.strDateString }} - {{ byWeek.endDateString }}
															</span>
														</b-col>
														<b-col cols="6">
															<div v-if="!isFilmSingle" style="margin-bottom: 8px">
																<div class="wrap-status d-flex justify-content-end">
																	<div :class="`status ${byWeek.statusClass}`" style="font-size: 0.675rem">
																		{{ byWeek.validatedStatus }}
																	</div>
																</div>
															</div>
														</b-col>
													</b-row>
													<b-row style="margin-top: 12px" class="text-center">
														<b-col v-if="canSeeSalary" cols="4">
															<h2>
																{{ getCurrency(byWeek.salary) }}
															</h2>
															<div class="d-flex flex-row align-items-center justify-content-center w-100">
																<salary-log :item="byWeek" />
																<p>{{ FormMSG(3, 'Salary') }}</p>
															</div>
														</b-col>
														<b-col cols="4" class="text-center">
															<h2>
																{{ getHhMmString(byWeek.hhmm) }}
															</h2>
															<p>
																<Clock10 color="#06263ED6" :size="16" />
																{{ FormMSG(4, 'Worked') }}
															</p>
														</b-col>
														<b-col cols="4" class="text-center pr-0 pl-0">
															<h2>
																{{ getCO2(byWeek.kgCoTwo) }}
															</h2>
															<p>
																<Sprout color="#00A09C" :size="16" />
																CO<sub>2</sub>
															</p>
														</b-col>
													</b-row>
												</b-col>
											</div>
										</div>
									</div>
								</b-collapse>
							</div>
						</div>
					</b-col>
				</b-row>
			</div>
		</div>
		<b-tabs v-else v-model="activeTabIndex" active-nav-item-class="font-weight-bold" active-tab-class="font-weight-bold">
			<b-tab :title="FormMSG(13031, 'Approve')" v-if="canSeeApprove"> <ValidateTimesheets /></b-tab>
			<b-tab :title="FormMSG(13034, 'Drafts')">
				<div class="container-mobile animated pt-0 fadeIn">
					<div class="content-myTimesheet-mobile pt-0">
						<p class="fw-400 my-1" style="font-size: 13px !important; color: rgba(6, 38, 62, 0.65)">
							* {{ FormMSG(1, 'Click to a timesheet to see the details.Timesheets are divided by week from Monday to Sunday.') }}
						</p>
						<b-row>
							<b-col>
								<div class="accordion" role="tablist" v-if="timesheetsByDate.length > 0">
									<div v-for="(timesheet, j) in timesheetsByDate" :key="j" style="margin-top: 16px">
										<div v-b-toggle="timesheet.accordionName" class="button-month-label" @click="handleAccordionClick(timesheet, j)">
											<div style="width: 90%">
												<p>
													{{ timesheet.month }}
												</p>
											</div>
											<div style="width: 10%" class="d-flex justify-content-end align-items-center">
												<component :is="getLucideIcon('ChevronDown')" color="#225CBD" :size="16" :stroke-width="2.5"> </component>
												<!-- <component :is="getLucideIcon('ChevronUp')" color="#225CBD" :size="16" :stroke-width="2.5" v-else></component> -->
											</div>
										</div>
										<b-collapse
											:id="timesheet.accordionName"
											accordion="accordion-my-timesheet-month"
											:visible="timesheet.visible"
											role="tabpanel"
										>
											<div v-if="timesheet.listWeeks.length === 0" class="text-center" style="margin-top: 16px; font-size: 0.9rem">
												{{ FormMSG(23, 'No timesheet found') }}
											</div>
											<div v-for="(byWeek, index) in timesheet.listWeeks" :key="index" style="padding: 8px">
												<div style="cursor: pointer" @click="handleClickRow(byWeek, timesheet.firstDateOfMonth)">
													<div
														style="background-color: #f5f7f9; padding: 16px; border-radius: 8px"
														class="d-flex flex-row justify-content-center align-items-start"
													>
														<b-col class="p-0">
															<b-row>
																<b-col cols="6">
																	<span class="text-left" style="font-size: 0.85rem !important; color: #06263e">
																		{{ byWeek.strDateString }} - {{ byWeek.endDateString }}
																	</span>
																</b-col>
																<b-col cols="6">
																	<div v-if="!isFilmSingle" style="margin-bottom: 8px">
																		<div class="wrap-status d-flex justify-content-end">
																			<div :class="`status ${byWeek.statusClass}`" style="font-size: 0.675rem">
																				{{ byWeek.validatedStatus }}
																			</div>
																		</div>
																	</div>
																</b-col>
															</b-row>
															<b-row style="margin-top: 12px" class="text-center">
																<b-col v-if="canSeeSalary" cols="4">
																	<h2>
																		{{ getCurrency(byWeek.salary) }}
																	</h2>
																	<div class="d-flex flex-row align-items-center justify-content-center w-100">
																		<salary-log :item="byWeek" />
																		<p>{{ FormMSG(3, 'Salary') }}</p>
																	</div>
																</b-col>
																<b-col cols="4" class="text-center">
																	<h2>
																		{{ getHhMmString(byWeek.hhmm) }}
																	</h2>
																	<p>
																		<Clock10 color="#06263ED6" :size="16" />
																		{{ FormMSG(4, 'Worked') }}
																	</p>
																</b-col>
																<b-col cols="4" class="text-center pr-0 pl-0">
																	<h2>
																		{{ getCO2(byWeek.kgCoTwo) }}
																	</h2>
																	<p>
																		<Sprout color="#00A09C" :size="16" />
																		CO<sub>2</sub>
																	</p>
																</b-col>
															</b-row>
														</b-col>
													</div>
												</div>
											</div>
										</b-collapse>
									</div>
								</div>
							</b-col>
						</b-row>
					</div>
				</div>
			</b-tab>
			<b-tab :title="FormMSG(13032, 'Pending')">
				<div class="container-mobile animated pt-0 fadeIn">
					<div class="content-myTimesheet-mobile pt-0">
						<p class="fw-400 my-1" style="font-size: 13px !important; color: rgba(6, 38, 62, 0.65)">
							* {{ FormMSG(1, 'Click to a timesheet to see the details.Timesheets are divided by week from Monday to Sunday.') }}
						</p>
						<b-row>
							<b-col>
								<div class="accordion" role="tablist" v-if="timesheetsByDate.length > 0">
									<div v-for="(timesheet, j) in timesheetsByDate" :key="j" style="margin-top: 16px">
										<div v-b-toggle="timesheet.accordionName" class="button-month-label" @click="handleAccordionClick(timesheet, j)">
											<div style="width: 90%">
												<p>
													{{ timesheet.month }}
												</p>
											</div>
											<div style="width: 10%" class="d-flex justify-content-end align-items-center">
												<component :is="getLucideIcon('ChevronDown')" color="#225CBD" :size="16" :stroke-width="2.5"> </component>
												<!-- <component :is="getLucideIcon('ChevronUp')" color="#225CBD" :size="16" :stroke-width="2.5" v-else></component> -->
											</div>
										</div>
										<b-collapse
											:id="timesheet.accordionName"
											accordion="accordion-my-timesheet-month"
											:visible="timesheet.visible"
											role="tabpanel"
										>
											<div v-if="timesheet.listWeeks.length === 0" class="text-center" style="margin-top: 16px; font-size: 0.9rem">
												{{ FormMSG(23, 'No timesheet found') }}
											</div>
											<div v-for="(byWeek, index) in timesheet.listWeeks" :key="index" style="padding: 8px">
												<div style="cursor: pointer" @click="handleClickRow(byWeek, timesheet.firstDateOfMonth)">
													<div
														style="background-color: #f5f7f9; padding: 16px; border-radius: 8px"
														class="d-flex flex-row justify-content-center align-items-start"
													>
														<b-col class="p-0">
															<b-row>
																<b-col cols="6">
																	<span class="text-left" style="font-size: 0.85rem !important; color: #06263e">
																		{{ byWeek.strDateString }} - {{ byWeek.endDateString }}
																	</span>
																</b-col>
																<b-col cols="6">
																	<div v-if="!isFilmSingle" style="margin-bottom: 8px">
																		<div class="wrap-status d-flex justify-content-end">
																			<div :class="`status ${byWeek.statusClass}`" style="font-size: 0.675rem">
																				{{ byWeek.validatedStatus }}
																			</div>
																		</div>
																	</div>
																</b-col>
															</b-row>
															<b-row style="margin-top: 12px" class="text-center">
																<b-col v-if="canSeeSalary" cols="4">
																	<h2>
																		{{ getCurrency(byWeek.salary) }}
																	</h2>
																	<div class="d-flex flex-row align-items-center justify-content-center w-100">
																		<salary-log :item="byWeek" />
																		<p>{{ FormMSG(3, 'Salary') }}</p>
																	</div>
																</b-col>
																<b-col cols="4" class="text-center">
																	<h2>
																		{{ getHhMmString(byWeek.hhmm) }}
																	</h2>
																	<p>
																		<Clock10 color="#06263ED6" :size="16" />
																		{{ FormMSG(4, 'Worked') }}
																	</p>
																</b-col>
																<b-col cols="4" class="text-center pr-0 pl-0">
																	<h2>
																		{{ getCO2(byWeek.kgCoTwo) }}
																	</h2>
																	<p>
																		<Sprout color="#00A09C" :size="16" />
																		CO<sub>2</sub>
																	</p>
																</b-col>
															</b-row>
														</b-col>
													</div>
												</div>
											</div>
										</b-collapse>
									</div>
								</div>
							</b-col>
						</b-row>
					</div>
				</div>
			</b-tab>
			<b-tab title-link-class="linkClass" :title="FormMSG(13033, 'Approved')">
				<div class="container-mobile pt-0 third animated fadeIn">
					<div class="content-myTimesheet-mobile pt-0">
						<p class="fw-400 my-1" style="font-size: 13px !important; color: rgba(6, 38, 62, 0.65)">
							* {{ FormMSG(1, 'Click to a timesheet to see the details.Timesheets are divided by week from Monday to Sunday.') }}
						</p>
						<b-row>
							<b-col>
								<div class="accordion" role="tablist" v-if="timesheetsByDate.length > 0">
									<div v-for="(timesheet, j) in timesheetsByDate" :key="j" style="margin-top: 16px">
										<div v-b-toggle="timesheet.accordionName" class="button-month-label" @click="handleAccordionClick(timesheet, j)">
											<div style="width: 90%">
												<p>
													{{ timesheet.month }}
												</p>
											</div>
											<div style="width: 10%" class="d-flex justify-content-end align-items-center">
												<component :is="getLucideIcon('ChevronDown')" color="#225CBD" :size="16" :stroke-width="2.5"> </component>
												<!-- <component :is="getLucideIcon('ChevronUp')" color="#225CBD" :size="16" :stroke-width="2.5" v-else></component> -->
											</div>
										</div>
										<b-collapse
											:id="timesheet.accordionName"
											accordion="accordion-my-timesheet-month"
											:visible="timesheet.visible"
											role="tabpanel"
										>
											<div v-if="timesheet.listWeeks.length === 0" class="text-center" style="margin-top: 16px; font-size: 0.9rem">
												{{ FormMSG(23, 'No timesheet found') }}
											</div>
											<div v-for="(byWeek, index) in timesheet.listWeeks" :key="index" style="padding: 8px">
												<div style="cursor: pointer" @click="handleClickRow(byWeek, timesheet.firstDateOfMonth)">
													<div
														style="background-color: #f5f7f9; padding: 16px; border-radius: 8px"
														class="d-flex flex-row justify-content-center align-items-start"
													>
														<b-col class="p-0">
															<b-row>
																<b-col cols="6">
																	<span class="text-left" style="font-size: 0.85rem !important; color: #06263e">
																		{{ byWeek.strDateString }} - {{ byWeek.endDateString }}
																	</span>
																</b-col>
																<b-col cols="6">
																	<div v-if="!isFilmSingle" style="margin-bottom: 8px">
																		<div class="wrap-status d-flex justify-content-end">
																			<div :class="`status ${byWeek.statusClass}`" style="font-size: 0.675rem">
																				{{ byWeek.validatedStatus }}
																			</div>
																		</div>
																	</div>
																</b-col>
															</b-row>
															<b-row style="margin-top: 12px" class="text-center">
																<b-col v-if="canSeeSalary" cols="4">
																	<h2>
																		{{ getCurrency(byWeek.salary) }}
																	</h2>
																	<div class="d-flex flex-row align-items-center justify-content-center w-100">
																		<salary-log :item="byWeek" />
																		<p>{{ FormMSG(3, 'Salary') }}</p>
																	</div>
																</b-col>
																<b-col cols="4" class="text-center">
																	<h2>
																		{{ getHhMmString(byWeek.hhmm) }}
																	</h2>
																	<p>
																		<Clock10 color="#06263ED6" :size="16" />
																		{{ FormMSG(4, 'Worked') }}
																	</p>
																</b-col>
																<b-col cols="4" class="text-center pr-0 pl-0">
																	<h2>
																		{{ getCO2(byWeek.kgCoTwo) }}
																	</h2>
																	<p>
																		<Sprout color="#00A09C" :size="16" />
																		CO<sub>2</sub>
																	</p>
																</b-col>
															</b-row>
														</b-col>
													</div>
												</div>
											</div>
										</b-collapse>
									</div>
								</div>
							</b-col>
						</b-row>
					</div>
				</div>
			</b-tab>
		</b-tabs>
		<div v-if="activeTabIndex === indexOfDraft && timeSheetsDrafts.length > 0 && canSubmit">
			<b-row class="footer-fixed">
				<b-col cols="12">
					<b-button variant="primary" @click="sendForValidation" block style="font-size: 16px; font-weight: 700">{{
						FormMSG(1521, 'Send all not submitted to validation')
					}}</b-button>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import { Clock10, Sprout } from 'lucide-vue';
import GlobalMixin from '@/mixins/global.mixin';
import moment from 'moment';
import { rendCurrency, rendKgCo2 } from '~helpers';
import { store } from '@/store';
import languageMessages from '@/mixins/languageMessages';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { AlertTriangle } from 'lucide-vue';
import * as icons from 'lucide-vue';
import ValidateTimesheets from '../../views/validateTS/ValidateTimesheets.vue';
import { submitTimeSheetsSlice } from '@/cruds/timesheets-efc.crud';
import SalaryLog from '@/components/Timesheet/SalaryLog';

export default {
	name: 'ListTimesheetMobile',
	components: {
		Clock10,
		Sprout,
		AlertTriangle,
		ValidateTimesheets,
		SalaryLog
	},
	mixins: [GlobalMixin, languageMessages, isSingleProjectMixin],
	props: {
		timeSheets: { type: Array },
		timeSheetsDrafts: { type: Array },
		timeSheetsApproved: { type: Array },
		timeSheetsPending: { type: Array },
		canSeeApprove: { type: Boolean, default: false }
	},
	data() {
		return {
			timesheetsByDate: [],
			activeTabIndex: 0,
			listTimesheet: [],
			timesheetArrayToUse: [],
			accordionActive: '',
			startYear: 0,
			endYear: 0,
			defaultCurrentYear: null,
			indexOfDraft: 0
		};
	},
	computed: {
		canSubmit() {
			return this.timeSheetsDrafts.some((obj) => obj.validated !== 4 && obj.validated !== 16);
		},
		statuses() {
			return this.FormMenu(1008);
		},
		canSeeSalary() {
			return !store.getCurrentProjectConfig().hideSalaryInfo || store.canViewGlobalBudget();
		}
	},
	watch: {
		timeSheets: {
			handler(val) {
				this.listTimesheet = val;
			},
			immediate: true,
			deep: true
		},
		activeTabIndex() {
			this.prepareListMonth();
		},
		'$route.query.activeTab': {
			handler(newVal) {
				if (newVal) {
					this.activeTabIndex = +newVal;
				}
			},
			immediate: true,
			deep: true
		}
	},
	async created() {
		moment.locale(this.lang);
		setTimeout(async () => {
			await this.prepareListMonth();
			if (this.canSeeApprove) {
				this.indexOfDraft = 1;
			}
		}, 1000); // add time to load data
	},
	methods: {
		async sendForValidation() {
			const action = async () => {
				for (let i = 0; i < this.timeSheetsDrafts.length; i++) {
					let tsDaysToSend = +this.timeSheetsDrafts[i].id;
					await submitTimeSheetsSlice(tsDaysToSend).then((res) => {
						if (res) {
							this.timeSheetsDrafts.filter((item) => item.id !== tsDaysToSend);
						}
					});
				}
				if (this.timeSheetsDrafts.length === 0) {
					let strTitle = this.FormMSG(14509, 'Success');
					this.createToastForMobile(strTitle, this.FormMSG(124101, 'All not submitted for validation send'), '', 'success', true);
				}
				this.$emit('submitted-all-ts', true);
				setTimeout(async () => {
					await this.prepareListMonth();
				}, 250);
			};
			this.confirmModal(action, this.FormMSG(4004, 'Please confirm you want to proceed?'));
		},
		getStatus(value) {
			const findIndex = this.statuses.findIndex((s) => parseInt(s.value) === parseInt(value));
			return this.statuses[findIndex].text;
		},
		handleClickRow(byWeek, firstDate) {
			store.state.monthTimesheetMobile = firstDate;
			store.setCurTimesheet(byWeek);
			this.$router.push({
				path: `/mytimesheets/${byWeek.id}?previousActiveTab=${this.activeTabIndex}`
			});
		},
		handleAccordionClick(timesheet, index) {
			const currMonthYear = `${timesheet.currMonth}-${timesheet.currYear}`;
			const isExpanded = this.timesheetsByDate[index]['accordion-month-' + currMonthYear];
			this.timesheetsByDate.forEach((ts, i) => {
				this.$set(ts, 'accordion-month-' + currMonthYear, i === index ? !isExpanded : false);
			});

			if (timesheet.listWeeks.length === 0) {
				const firstDateOfMonth = moment(timesheet.firstDateOfMonth);
				timesheet.listWeeks = this.prepareListWeek(firstDateOfMonth);
			}
			this.accordionActive = timesheet.accordionName;
		},
		timesheetAccordionName(timesheet) {
			const monthIndex = this.timesheetsByDate.findIndex((m) => m.month === timesheet.month);
			return this.timesheetsByDate[monthIndex][`accordion-month-${timesheet.currMonth}`];
		},
		async prepareListMonth(index, init = true) {
			this.timesheetsByDate = [];
			this.timesheetArrayToUse = [];
			let newTsArray = [];

			if (this.isFilmSingle) {
				newTsArray = this.listTimesheet;
			} else {
				let pointOnExactTab = -1;

				if (this.canSeeApprove) {
					pointOnExactTab = 0;
				}

				if (this.activeTabIndex === 1 + pointOnExactTab) {
					newTsArray = this.timeSheetsDrafts;
				} else if (this.activeTabIndex === 2 + pointOnExactTab) {
					newTsArray = this.timeSheetsPending;
				} else if (this.activeTabIndex === 3 + pointOnExactTab) {
					newTsArray = this.timeSheetsApproved;
				}
			}

			this.timesheetArrayToUse = newTsArray;
			const years = this.getUniqueYearsFromTimesheet();
			for (const year of years) {
				for (let month = 12; month >= 1; month--) {
					const date = moment(new Date(year, month, 1));
					const firstDateOfMonth = date.startOf('month');
					const accordionName = `accordion-month-${date.format('YYYY-MM')}`;
					let listWeeks = [];
					let visible = false;

					// Check if there are timesheets for this month and year
					const timesheetsExist = this.timesheetArrayToUse.some((timesheet) => {
						const timesheetDate = moment(timesheet.strDate);
						return timesheetDate.format('YYYY-MM') === date.format('YYYY-MM');
					});

					if (timesheetsExist) {
						if (!init) {
							// check collapse
							const monthIndex = this.timesheetsByDate.findIndex((m) => m.month === date.format('MMMM YYYY'));
							this.$set(
								this.timesheetsByDate[monthIndex],
								`accordion-year-${year}`,
								!this.timesheetsByDate[monthIndex][`accordion-year-${year}`]
							);

							let inc = 0;
							for (const ts of this.timesheetsByDate) {
								if (inc !== monthIndex) {
									this.$set(this.timesheetsByDate[inc], `accordion-year-${ts.currYear}`, false);
								}
								inc++;
							}
						}

						if (store.state.monthTimesheetMobile) {
							const monthTimesheetMobile = moment(store.state.monthTimesheetMobile);
							if (date.format('MMMM YYYY') === monthTimesheetMobile.format('MMMM YYYY')) {
								this.accordionActive = accordionName;
								listWeeks = this.prepareListWeek(firstDateOfMonth);
								visible = true;
							}
						} else {
							if (moment().format('MMMM YYYY') === date.format('MMMM YYYY')) {
								this.accordionActive = accordionName;
								listWeeks = this.prepareListWeek(firstDateOfMonth);
								visible = true;
							}
						}

						this.timesheetsByDate.push({
							month: date.format('MMMM YYYY'),
							accordionName,
							firstDateOfMonth,
							visible,
							[accordionName]: visible,
							currMonth: month,
							currYear: year,
							lastDateOfMonth: date.endOf('month'),
							listWeeks
						});
						this.timesheetsByDate[0]['accordion-month-' + `${this.timesheetsByDate[0].currMonth}`] = true;

						// Set the rest of the months to be collapsed by default
						for (let i = 1; i < this.timesheetsByDate.length; i++) {
							this.timesheetsByDate[i]['accordion-month-' + `${this.timesheetsByDate[i].currMonth}`] = false;
						}
					}
				}
			}
		},
		getUniqueYearsFromTimesheet() {
			const years = new Set();
			for (const timesheet of this.listTimesheet) {
				years.add(moment(timesheet.strDate).year());
			}
			return Array.from(years);
		},
		prepareListWeek(firstDate) {
			let result = [];
			if (this.timesheetArrayToUse.length > 0) {
				// for (let i = 0; i < this.listTimesheet.length; i++) {
				for (let i = this.timesheetArrayToUse.length - 1; i >= 0; i--) {
					const timeSheet = this.timesheetArrayToUse[i];
					const startDate = moment(timeSheet.strDate);
					if (firstDate.month() === startDate.month() && firstDate.year() === startDate.year()) {
						result.push({
							...timeSheet,
							strDateString: moment(new Date(timeSheet.strDate)).format('dddd, DD MMMM'),
							endDateString: moment(new Date(timeSheet.endDate)).format('dddd, DD MMMM')
						});
					}
				}
			}

			return result;
		},
		getCO2(val) {
			return rendKgCo2(val);
		},
		getCurrency(val) {
			return rendCurrency(val);
		},
		getHhMmString(value) {
			let splitValue = value.split(':');
			if (parseInt(splitValue[0], 10) < 10) {
				splitValue[0] = '0' + parseInt(splitValue[0]);
			}
			if (parseInt(splitValue[1], 10) < 10) {
				splitValue[1] = '0' + parseInt(splitValue[1]);
			}
			return `${splitValue[0]}h ${splitValue[1]}m`;
		},
		getLucideIcon(name) {
			return icons[name];
		},
		onlyMonth(value) {
			return value.split(' ')[0];
		}
	}
};
</script>

<style>
.empty-data {
	color: rgba(6, 38, 62, 0.74);
	font-size: 0.875rem;
}
.my-project .nav-tabs {
	border-bottom: 0;
	margin-left: -16px;
	margin-right: -16px;
}
</style>
